/* Votre Style */
.box {
  padding: 4rem 7%;
}
.site-logo, .site-logo:hover {
  text-decoration: none;
}
.site-logo:hover {
  color: deepskyblue;
}
a:hover {
  text-decoration-color: deepskyblue;
}
.main-navigation {
  text-align: right;
}
.main-navigation ul {
  list-style: none;
  padding-left: 0;
  display: inline-block;
  min-width: 320px;
}
.main-nav-item {
  vertical-align: middle;
  display: inline-block;
}
.main-nav-link {
  display: inline-block;
}
.main-nav-item+.main-nav-item {
  margin-left: 3%;
}
header, footer {
  background: #ddd;
}
main {
  background: #eee;
}
aside {
  background: #aaa;
}
pre.language-js {
	padding: .4em .8em;
	margin: .5em 0;
	overflow: auto;
	background: url('data:image/svg+xml;charset=utf-8,<svg%20version%3D"1.1"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20width%3D"100"%20height%3D"100"%20fill%3D"rgba(0%2C0%2C0%2C.2)">%0D%0A<polygon%20points%3D"0%2C50%2050%2C0%200%2C0"%20%2F>%0D%0A<polygon%20points%3D"0%2C100%2050%2C100%20100%2C50%20100%2C0"%20%2F>%0D%0A<%2Fsvg>');
	background-size: 1em 1em;
}
#inputBody {
  width: 100%;
  max-width: 600px;
  margin-bottom: 5px;
}
@media (max-width: 380px) {
  .main-navigation {
    text-align: left;
  }
}